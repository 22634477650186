import React from "react"
import Layout from "../components/Layout"

const NoPage = () => {
  return (
    <Layout>
      <div className="page">
        <div className="central">
          <h2>404</h2>
          <p>Sorry, that page doesn't exist.</p>
        </div>
      </div>
    </Layout>
  )
}

export default NoPage
